import type {
  ISbDimensions,
  ISbResult,
  ISbStories,
  ISbStoriesParams,
} from 'storyblok-js-client'
import type { SbBlokData, StoryblokBridgeConfigV2 } from '@storyblok/vue'

import type { AsyncDataOptions } from '#app'
import type { ContentTypePropertyStoryblok } from '@/types/storyblok'
import type { ISbStoryData } from 'storyblok'

export interface ISbLinksParams
  extends Pick<
    ISbStoriesParams,
    | 'token'
    | 'starts_with'
    | 'version'
    | 'cv'
    | 'with_parent'
    | 'page'
    | 'per_page'
  > {
  include_dates?: number
  pagination?: number
}
export type ISbDatasourcesParams = Pick<
  ISbStoriesParams,
  'token' | 'page' | 'per_page'
>
export interface ISbDatasourcesEntriesParams
  extends Pick<ISbStoriesParams, 'token' | 'page' | 'per_page'> {
  datasource: string
  dimension?: string
}

export interface ISbDatasources extends ISbResult {
  data: {
    datasources: Datasource[]
  }
}
export interface ISbDatasourceEntries extends ISbResult {
  data: {
    datasource_entries: DatasourceEntry[]
  }
}
export interface Datasource {
  id: number
  name: string
  slug: string
  dimensions: ISbDimensions['dimensions']
}
export interface DatasourceEntry {
  id: number
  name: string
  value: string
  dimension_value?: string
}

export type FetchDataType =
  | 'stories'
  | 'links'
  | 'datasources'
  | 'datasource_entries'
export type FetchAPIOptions =
  | ISbStoriesParams
  | ISbLinksParams
  | ISbDatasourcesParams
  | ISbDatasourcesEntriesParams

export interface IGenericSbStories<T> extends Omit<ISbStories, 'data'> {
  data: Omit<ISbStories['data'], 'stories'> & {
    stories: ISbStoryData<T>[]
  }
}
export interface BatchedPropertiesResponse {
  stories: ISbStoryData<ContentTypePropertyStoryblok>[]
  total: number
}

export default function () {
  const { query } = useRoute()
  const isStoryblokEditor = computed(() => !!query._storyblok)
  const storyblokApiInstance = useStoryblokApi()
  const appConfig = useAppConfig()

  // TODO: This is a workaround to force the cache to invalidate on each page load.
  // This is a temporary solution until we can implement a more robust cache invalidation strategy for the server side (or SB SDK is fixed)
  // REFS:
  // https://github.com/storyblok/storyblok-js-client/issues/806
  // https://github.com/storyblok/storyblok-nuxt/issues/873
  // https://github.com/storyblok/storyblok-js-client/issues/823
  const storyblokCacheVersion = computed(() => Date.now())

  /**
   * Version of Storyblok story to return.
   * Always returns 'draft' within the visual editor to use bridge functionality.
   */
  const storyblokVersion = computed(() =>
    isStoryblokEditor.value ||
    useRuntimeConfig().public.storyblokVersion === 'draft'
      ? 'draft'
      : 'published',
  )

  /**
   * Async GET request for single story - used on page load.
   * Also establishes the Storyblok bridge functionality.
   */
  const fetchAsyncStory = <T = SbBlokData>(
    url: MaybeRefOrGetter<string>,
    apiOptions: MaybeRefOrGetter<
      Omit<ISbStoriesParams, 'resolve_relations' | 'version'>
    > = {},
    bridgeOptions: MaybeRefOrGetter<
      Omit<StoryblokBridgeConfigV2, 'resolveRelations'>
    > = {},
  ) => {
    const amendedApiOptions: ISbStoriesParams = {
      ...toValue(apiOptions),
      resolve_relations: appConfig.storyblok.resolveRelations.join(','),
      version: storyblokVersion.value,
    }

    const amendedBridgeOptions: StoryblokBridgeConfigV2 = {
      ...toValue(bridgeOptions),
      resolveRelations: appConfig.storyblok.resolveRelations.join(','),
    }

    return useAsyncStoryblok(
      toValue(url),
      amendedApiOptions,
      amendedBridgeOptions,
    ) as Promise<Ref<ISbStoryData<T>>>
  }

  /**
   * Async GET request to the Content Delivery API.
   * Caches requests using useState().
   */
  const fetchAsyncData = <T, K = IGenericSbStories<T>>(
    dataType: MaybeRefOrGetter<FetchDataType>,
    apiOptions: MaybeRefOrGetter<FetchAPIOptions> = {},
    options?: AsyncDataOptions<K>,
  ) => {
    const uniqueKey = computed(() => JSON.stringify(toValue(apiOptions)))
    return useAsyncData<K>(
      uniqueKey.value,
      () =>
        storyblokApiInstance
          .get(`cdn/${toValue(dataType)}/`, {
            ...toValue(apiOptions),
            version: storyblokVersion.value,
            cv: storyblokCacheVersion.value,
          })
          .then((response) => response as K),
      {
        getCachedData(key, nuxtApp) {
          return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        },
        ...options,
      },
    )
  }

  /**
   * Async GET request to the Content Delivery API; iterates over all pages and returns one dataset.
   * Caches requests using useState().
   */
  const fetchAsyncDataPaginated = <T>(
    dataType: MaybeRefOrGetter<FetchDataType>,
    apiOptions: MaybeRefOrGetter<FetchAPIOptions> = {},
    options?: AsyncDataOptions<IGenericSbStories<T>>,
  ) => {
    const uniqueKey = computed(
      () => JSON.stringify(toValue(apiOptions)) + '-paginated',
    )
    const fetchPage = (page?: number) =>
      storyblokApiInstance
        .get(`cdn/${toValue(dataType)}/`, {
          ...toValue(apiOptions),
          // Override page only if param provided
          ...(page && { page }),
          version: storyblokVersion.value,
          cv: storyblokCacheVersion.value,
        })
        .then((response) => response as IGenericSbStories<T>)

    // Iterates over every page to accumulate all data into one array of stories
    return useAsyncData<IGenericSbStories<T>>(
      uniqueKey.value,
      async () => {
        const allData = {
          data: {
            stories: [] as ISbStoryData<T>[],
          },
        } as IGenericSbStories<T>

        let currentPage = 1
        let totalPages = 1

        do {
          const response = await fetchPage(currentPage)

          // Combine stories
          allData.data.stories.push(...response.data.stories)
          // Get the total number of pages from the response
          totalPages =
            Math.ceil(response.headers.total / response.headers['per-page']) ||
            1
          currentPage++
        } while (currentPage <= totalPages)

        // Return the accumulated data
        return allData
      },
      {
        getCachedData(key, nuxtApp) {
          return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        },
        ...options,
      },
    )
  }

  /**
   * Fetches properties based on filtered ERP data; batching the fetch requests by chunks of 50 ERP data uuids.
   * Caches requests using useState().
   */
  const fetchAsyncPropertyDataBatched = (
    erpUuids: MaybeRefOrGetter<string[] | undefined>,
    perPage: MaybeRefOrGetter<number>,
    currentPage: MaybeRefOrGetter<number>,
    apiOptions: MaybeRefOrGetter<ISbStoriesParams> = {},
    options?: AsyncDataOptions<BatchedPropertiesResponse>,
  ) => {
    const uniqueKey = computed(
      () =>
        `${JSON.stringify(toValue(apiOptions))}-${JSON.stringify(toValue(erpUuids))}`,
    )

    // Define the chunk size for the UUIDs
    const CHUNK_SIZE = 50

    return useAsyncData<BatchedPropertiesResponse>(
      uniqueKey.value,
      async () => {
        const uuidChunks = chunkArray(toValue(erpUuids) || [], CHUNK_SIZE)

        const batchedFetchRequests = uuidChunks.map((uuidChunk) => {
          // Create API options for the specific chunk
          const apiOptionsForChunk = {
            ...toValue(apiOptions),
            per_page: 100,
            filter_query: {
              ...toValue(apiOptions).filter_query,
              erpProperty: {
                in: uuidChunk.join(','),
              },
            },
          }

          return storyblokApiInstance
            .get('cdn/stories/', {
              ...apiOptionsForChunk,
              version: storyblokVersion.value,
              cv: storyblokCacheVersion.value,
            })
            .then(
              (response) =>
                response as IGenericSbStories<ContentTypePropertyStoryblok>,
            )
        })

        // Wait for all batched fetches to complete
        const results = await Promise.all(batchedFetchRequests)

        // Initialize to track the combined data and total
        const allStories: ISbStoryData<ContentTypePropertyStoryblok>[] = []
        let combinedTotal = 0

        // Iterate over each result, combining stories and summing totals
        results.forEach((response) => {
          allStories.push(...response.data.stories) // Combine stories
          combinedTotal += parseInt(response.headers.total) || 0 // Sum the totals from each response
        })

        const page = toValue(currentPage)
        const pageSize = toValue(perPage)
        const startIndex = (page - 1) * pageSize
        const paginatedStories = allStories.slice(
          startIndex,
          startIndex + pageSize,
        )

        // Return the combined stories and the total sum of all responses
        return {
          stories: paginatedStories,
          total: combinedTotal,
        }
      },
      {
        getCachedData(key, nuxtApp) {
          return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        },
        ...options,
      },
    )
  }

  return {
    isStoryblokEditor,
    storyblokVersion,
    fetchAsyncStory,
    fetchAsyncData,
    fetchAsyncDataPaginated,
    fetchAsyncPropertyDataBatched,
  }
}
